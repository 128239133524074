<template>
  <Tab :list="tabList" :current="1" />
  <div class="flex-1 fff flex-col">
    <!-- <van-row class="row">
      <van-col
        v-for="(item, index) in detail.list"
        :key="index"
        :span="item.span"
        class="col"
      >
        <span class="label">{{ item.label }}：</span> {{ item.value }}
      </van-col>
    </van-row> -->
    <van-row class="row">
      <van-col span="24" class="col">
        <span class="label">监管单位信息：</span> {{ detail.info.deptName }}
      </van-col>
      <van-col span="24" class="col">
        <span class="label">市场负责人：</span>
        {{ detail.info.managerUserName }}
      </van-col>
      <van-col span="24" class="col">
        <span class="label">举报电话：</span> {{ detail.info.mobile }}
      </van-col>
    </van-row>
    <!-- <div id="map" class="flex-1"></div> -->
    <div id="container" class="Gmap"></div>
  </div>
</template>

<script>
import { supervisionTabList } from '@/utils/tab'
import { reactive } from '@vue/reactivity'
import Tab from '@/components/tab/index.vue'
// import { getInfo } from '@/api/supervision'
import { getDetail } from '@/api/supervision/index'
import { baiduMap } from '@/utils/baiduMap'
import { onMounted } from '@vue/runtime-core'

export default {
  components: {
    Tab,
  },
  setup() {
    const tabList = reactive(supervisionTabList)

    let detail = reactive({
      list: [],
      info: {},
    })
    // getInfo().then((res) => {
    //   detail.list = res.list
    // })

    const ak = 'VQUox5fjaMEOPIyvDrlbGdLCVkS3HAmO'

    onMounted(() => {
      getDetail(3).then((res) => {
        detail.info = res.result[0]
        var map = new AMap.Map('container', {
          resizeEnable: true,
          zoom: 17,
          center: [detail.info.longitude * 1, detail.info.latitude * 1],
          viewMode: '3D', //使用3D视图
          mapStyle: 'amap://styles/fresh',
          //前往创建自定义地图样式：https://lbs.amap.com/dev/mapstyle/index
        })
      })

      // baiduMap(ak)
      //   .then((BMapGL) => {
      //     // 创建地图实例
      //     let map = new BMapGL.Map('map')
      //     // 创建点坐标 axios => res 获取的初始化定位坐标
      //     let point = new BMapGL.Point(detail.info.longitude, detail.info.latitude)
      //     // 初始化地图，设置中心点坐标和地图级别
      //     map.centerAndZoom(point, 19)
      //     //开启鼠标滚轮缩放
      //     map.enableScrollWheelZoom(true)
      //     map.setHeading(64.5)
      //     map.setTilt(73)
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
    })

    return {
      tabList,
      detail,
    }
  },
}
</script>

<style lang="less" scoped>
.row {
  padding: 12px;
}
.col {
  display: flex;
  align-items: center;
  color: #666;
  line-height: 24px;
}
.label {
  width: 110px;
  text-align: right;
  color: #333;
}

.Gmap {
  height: 100%;
}
</style>
